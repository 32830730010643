{
  "path": "../src/public/",
  "name": "public",
  "children": [
    {
      "path": "../src/public/IMG_0098.png",
      "name": "IMG_0098.png",
      "size": 1739986,
      "extension": ".png",
      "type": "file"
    },
    {
      "path": "../src/public/axel",
      "name": "axel",
      "children": [
        {
          "path": "../src/public/axel/IMG_9950.png",
          "name": "IMG_9950.png",
          "size": 1153927,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9951.png",
          "name": "IMG_9951.png",
          "size": 1103628,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9952.png",
          "name": "IMG_9952.png",
          "size": 973192,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9953.png",
          "name": "IMG_9953.png",
          "size": 849947,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9954.png",
          "name": "IMG_9954.png",
          "size": 1032119,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9955.png",
          "name": "IMG_9955.png",
          "size": 1118378,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9956.png",
          "name": "IMG_9956.png",
          "size": 1012405,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9957.png",
          "name": "IMG_9957.png",
          "size": 1160752,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9958.png",
          "name": "IMG_9958.png",
          "size": 1044947,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9959.png",
          "name": "IMG_9959.png",
          "size": 1019412,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9960.png",
          "name": "IMG_9960.png",
          "size": 1192652,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9961.png",
          "name": "IMG_9961.png",
          "size": 1005503,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9962.png",
          "name": "IMG_9962.png",
          "size": 1044386,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9963.png",
          "name": "IMG_9963.png",
          "size": 1136844,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9964.png",
          "name": "IMG_9964.png",
          "size": 975986,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9965.png",
          "name": "IMG_9965.png",
          "size": 1151990,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9966.png",
          "name": "IMG_9966.png",
          "size": 1132260,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9967.png",
          "name": "IMG_9967.png",
          "size": 1047098,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9968.png",
          "name": "IMG_9968.png",
          "size": 1051253,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9969.png",
          "name": "IMG_9969.png",
          "size": 997428,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9970.png",
          "name": "IMG_9970.png",
          "size": 1165980,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9971.png",
          "name": "IMG_9971.png",
          "size": 1130565,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9972.png",
          "name": "IMG_9972.png",
          "size": 1177901,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9973.png",
          "name": "IMG_9973.png",
          "size": 998877,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/axel/IMG_9974.png",
          "name": "IMG_9974.png",
          "size": 1142473,
          "extension": ".png",
          "type": "file"
        }
      ],
      "size": 26819903,
      "type": "directory"
    },
    {
      "path": "../src/public/connectors",
      "name": "connectors",
      "children": [
        {
          "path": "../src/public/connectors/IMG_0001.png",
          "name": "IMG_0001.png",
          "size": 1032999,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0002.png",
          "name": "IMG_0002.png",
          "size": 1145340,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0003.png",
          "name": "IMG_0003.png",
          "size": 1134868,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0004.png",
          "name": "IMG_0004.png",
          "size": 957423,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0005.png",
          "name": "IMG_0005.png",
          "size": 1081585,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0006.png",
          "name": "IMG_0006.png",
          "size": 1026890,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0007.png",
          "name": "IMG_0007.png",
          "size": 1086993,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0008.png",
          "name": "IMG_0008.png",
          "size": 1112705,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0009.png",
          "name": "IMG_0009.png",
          "size": 1155173,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0010.png",
          "name": "IMG_0010.png",
          "size": 907951,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0011.png",
          "name": "IMG_0011.png",
          "size": 1174386,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0012.png",
          "name": "IMG_0012.png",
          "size": 1237869,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0013.png",
          "name": "IMG_0013.png",
          "size": 1016181,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0014.png",
          "name": "IMG_0014.png",
          "size": 1065246,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0015.png",
          "name": "IMG_0015.png",
          "size": 1171505,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0016.png",
          "name": "IMG_0016.png",
          "size": 1089832,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0017.png",
          "name": "IMG_0017.png",
          "size": 1151044,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0018.png",
          "name": "IMG_0018.png",
          "size": 1016838,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0019.png",
          "name": "IMG_0019.png",
          "size": 1117431,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0020.png",
          "name": "IMG_0020.png",
          "size": 997444,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0021.png",
          "name": "IMG_0021.png",
          "size": 1126159,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0022.png",
          "name": "IMG_0022.png",
          "size": 1192559,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0023.png",
          "name": "IMG_0023.png",
          "size": 1040834,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0024.png",
          "name": "IMG_0024.png",
          "size": 1177164,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0025.png",
          "name": "IMG_0025.png",
          "size": 1020203,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0026.png",
          "name": "IMG_0026.png",
          "size": 1131799,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0027.png",
          "name": "IMG_0027.png",
          "size": 1195904,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0028.png",
          "name": "IMG_0028.png",
          "size": 1091133,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0029.png",
          "name": "IMG_0029.png",
          "size": 989975,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0030.png",
          "name": "IMG_0030.png",
          "size": 1044388,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0032.png",
          "name": "IMG_0032.png",
          "size": 1188557,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0033.png",
          "name": "IMG_0033.png",
          "size": 1196309,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0034.png",
          "name": "IMG_0034.png",
          "size": 1047722,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0035.png",
          "name": "IMG_0035.png",
          "size": 997086,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0036.png",
          "name": "IMG_0036.png",
          "size": 1026126,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_0037.png",
          "name": "IMG_0037.png",
          "size": 1111358,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9975.png",
          "name": "IMG_9975.png",
          "size": 1202499,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9976.png",
          "name": "IMG_9976.png",
          "size": 1283400,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9977.png",
          "name": "IMG_9977.png",
          "size": 1171170,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9978.png",
          "name": "IMG_9978.png",
          "size": 1036414,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9980.png",
          "name": "IMG_9980.png",
          "size": 1171360,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9981.png",
          "name": "IMG_9981.png",
          "size": 1171340,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9982.png",
          "name": "IMG_9982.png",
          "size": 1148065,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9983.png",
          "name": "IMG_9983.png",
          "size": 1067636,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9984.png",
          "name": "IMG_9984.png",
          "size": 1182817,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9985.png",
          "name": "IMG_9985.png",
          "size": 1047514,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9986.png",
          "name": "IMG_9986.png",
          "size": 1283947,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9987.png",
          "name": "IMG_9987.png",
          "size": 981629,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9988.png",
          "name": "IMG_9988.png",
          "size": 1162654,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9989.png",
          "name": "IMG_9989.png",
          "size": 1283331,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9990.png",
          "name": "IMG_9990.png",
          "size": 1200479,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9991.png",
          "name": "IMG_9991.png",
          "size": 1067686,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9992.png",
          "name": "IMG_9992.png",
          "size": 1094725,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9993.png",
          "name": "IMG_9993.png",
          "size": 1025085,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9994.png",
          "name": "IMG_9994.png",
          "size": 871829,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9995.png",
          "name": "IMG_9995.png",
          "size": 908232,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9996.png",
          "name": "IMG_9996.png",
          "size": 959489,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9997.png",
          "name": "IMG_9997.png",
          "size": 1073614,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9998.png",
          "name": "IMG_9998.png",
          "size": 857496,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/connectors/IMG_9999.png",
          "name": "IMG_9999.png",
          "size": 1151938,
          "extension": ".png",
          "type": "file"
        }
      ],
      "size": 65661328,
      "type": "directory"
    },
    {
      "path": "../src/public/decorations",
      "name": "decorations",
      "children": [
        {
          "path": "../src/public/decorations/IMG_0038.png",
          "name": "IMG_0038.png",
          "size": 1095677,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0039.png",
          "name": "IMG_0039.png",
          "size": 1185768,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0040.png",
          "name": "IMG_0040.png",
          "size": 1308109,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0041.png",
          "name": "IMG_0041.png",
          "size": 1014100,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0042.png",
          "name": "IMG_0042.png",
          "size": 1038828,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0043.png",
          "name": "IMG_0043.png",
          "size": 1077401,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0044.png",
          "name": "IMG_0044.png",
          "size": 1175862,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0045.png",
          "name": "IMG_0045.png",
          "size": 1037657,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0046.png",
          "name": "IMG_0046.png",
          "size": 1053789,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0047.png",
          "name": "IMG_0047.png",
          "size": 989946,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/decorations/IMG_0048.png",
          "name": "IMG_0048.png",
          "size": 1033436,
          "extension": ".png",
          "type": "file"
        }
      ],
      "size": 12010573,
      "type": "directory"
    },
    {
      "path": "../src/public/fasteners",
      "name": "fasteners",
      "children": [
        {
          "path": "../src/public/fasteners/IMG_9862.png",
          "name": "IMG_9862.png",
          "size": 1011832,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9863.png",
          "name": "IMG_9863.png",
          "size": 1140370,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9864.png",
          "name": "IMG_9864.png",
          "size": 1013740,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9865.png",
          "name": "IMG_9865.png",
          "size": 1127967,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9866.png",
          "name": "IMG_9866.png",
          "size": 1145513,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9867.png",
          "name": "IMG_9867.png",
          "size": 1014793,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9868.png",
          "name": "IMG_9868.png",
          "size": 1009909,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9869.png",
          "name": "IMG_9869.png",
          "size": 1023521,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9870.png",
          "name": "IMG_9870.png",
          "size": 1102503,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9871.png",
          "name": "IMG_9871.png",
          "size": 938888,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9872.png",
          "name": "IMG_9872.png",
          "size": 1023995,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9873.png",
          "name": "IMG_9873.png",
          "size": 1038398,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9874.png",
          "name": "IMG_9874.png",
          "size": 1034747,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9875.png",
          "name": "IMG_9875.png",
          "size": 941680,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9876.png",
          "name": "IMG_9876.png",
          "size": 1091963,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9877.png",
          "name": "IMG_9877.png",
          "size": 983108,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9878.png",
          "name": "IMG_9878.png",
          "size": 1102343,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9879.png",
          "name": "IMG_9879.png",
          "size": 1123154,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9880.png",
          "name": "IMG_9880.png",
          "size": 1113126,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9881.png",
          "name": "IMG_9881.png",
          "size": 1017808,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9882.png",
          "name": "IMG_9882.png",
          "size": 1162808,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9883.png",
          "name": "IMG_9883.png",
          "size": 964424,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9884.png",
          "name": "IMG_9884.png",
          "size": 1121013,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9885.png",
          "name": "IMG_9885.png",
          "size": 1147717,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9886.png",
          "name": "IMG_9886.png",
          "size": 1028691,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9887.png",
          "name": "IMG_9887.png",
          "size": 1078597,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9888.png",
          "name": "IMG_9888.png",
          "size": 1137539,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9889.png",
          "name": "IMG_9889.png",
          "size": 997485,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9890.png",
          "name": "IMG_9890.png",
          "size": 1096759,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9891.png",
          "name": "IMG_9891.png",
          "size": 1017865,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9892.png",
          "name": "IMG_9892.png",
          "size": 1137278,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9893.png",
          "name": "IMG_9893.png",
          "size": 1148505,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9894.png",
          "name": "IMG_9894.png",
          "size": 1014738,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9895.png",
          "name": "IMG_9895.png",
          "size": 1153276,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9896.png",
          "name": "IMG_9896.png",
          "size": 1127202,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9897.png",
          "name": "IMG_9897.png",
          "size": 1000945,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9898.png",
          "name": "IMG_9898.png",
          "size": 1015635,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9899.png",
          "name": "IMG_9899.png",
          "size": 1148027,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9900.png",
          "name": "IMG_9900.png",
          "size": 1140531,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9901.png",
          "name": "IMG_9901.png",
          "size": 1024072,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9902.png",
          "name": "IMG_9902.png",
          "size": 1154918,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9903.png",
          "name": "IMG_9903.png",
          "size": 1148300,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9904.png",
          "name": "IMG_9904.png",
          "size": 908793,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9905.png",
          "name": "IMG_9905.png",
          "size": 1060998,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9906.png",
          "name": "IMG_9906.png",
          "size": 1127767,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9907.png",
          "name": "IMG_9907.png",
          "size": 1129210,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9908.png",
          "name": "IMG_9908.png",
          "size": 1002674,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9909.png",
          "name": "IMG_9909.png",
          "size": 1012427,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9910.png",
          "name": "IMG_9910.png",
          "size": 1050697,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9911.png",
          "name": "IMG_9911.png",
          "size": 1195203,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9912.png",
          "name": "IMG_9912.png",
          "size": 1173262,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9913.png",
          "name": "IMG_9913.png",
          "size": 1065234,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9914.png",
          "name": "IMG_9914.png",
          "size": 1010453,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9915.png",
          "name": "IMG_9915.png",
          "size": 1186493,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9916.png",
          "name": "IMG_9916.png",
          "size": 1206786,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9917.png",
          "name": "IMG_9917.png",
          "size": 1177013,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9918.png",
          "name": "IMG_9918.png",
          "size": 1038906,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9919.png",
          "name": "IMG_9919.png",
          "size": 1169058,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9920.png",
          "name": "IMG_9920.png",
          "size": 1169041,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9921.png",
          "name": "IMG_9921.png",
          "size": 1121050,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9922.png",
          "name": "IMG_9922.png",
          "size": 1143925,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9923.png",
          "name": "IMG_9923.png",
          "size": 1163764,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9924.png",
          "name": "IMG_9924.png",
          "size": 1002027,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9925.png",
          "name": "IMG_9925.png",
          "size": 1050646,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9926.png",
          "name": "IMG_9926.png",
          "size": 1008318,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9927.png",
          "name": "IMG_9927.png",
          "size": 1178901,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9928.png",
          "name": "IMG_9928.png",
          "size": 1167913,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9929.png",
          "name": "IMG_9929.png",
          "size": 1147611,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9930.png",
          "name": "IMG_9930.png",
          "size": 1025580,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9931.png",
          "name": "IMG_9931.png",
          "size": 1027266,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9932.png",
          "name": "IMG_9932.png",
          "size": 1049809,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9933.png",
          "name": "IMG_9933.png",
          "size": 1143082,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9934.png",
          "name": "IMG_9934.png",
          "size": 974304,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9935.png",
          "name": "IMG_9935.png",
          "size": 1035737,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9936.png",
          "name": "IMG_9936.png",
          "size": 1114518,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9937.png",
          "name": "IMG_9937.png",
          "size": 1123228,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9938.png",
          "name": "IMG_9938.png",
          "size": 1021777,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9939.png",
          "name": "IMG_9939.png",
          "size": 1078232,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9940.png",
          "name": "IMG_9940.png",
          "size": 1085036,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9941.png",
          "name": "IMG_9941.png",
          "size": 1143183,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9942.png",
          "name": "IMG_9942.png",
          "size": 1039640,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9943.png",
          "name": "IMG_9943.png",
          "size": 1121059,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9944.png",
          "name": "IMG_9944.png",
          "size": 1017309,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9945.png",
          "name": "IMG_9945.png",
          "size": 1177751,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9946.png",
          "name": "IMG_9946.png",
          "size": 1033124,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9947.png",
          "name": "IMG_9947.png",
          "size": 1149467,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9948.png",
          "name": "IMG_9948.png",
          "size": 1158003,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/fasteners/IMG_9949.png",
          "name": "IMG_9949.png",
          "size": 1074589,
          "extension": ".png",
          "type": "file"
        }
      ],
      "size": 95226547,
      "type": "directory"
    },
    {
      "path": "../src/public/gears",
      "name": "gears",
      "children": [
        {
          "path": "../src/public/gears/IMG_9823.png",
          "name": "IMG_9823.png",
          "size": 1114487,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9824.png",
          "name": "IMG_9824.png",
          "size": 975958,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9825.png",
          "name": "IMG_9825.png",
          "size": 1104584,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9827.png",
          "name": "IMG_9827.png",
          "size": 1098694,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9828.png",
          "name": "IMG_9828.png",
          "size": 990688,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9829.png",
          "name": "IMG_9829.png",
          "size": 940116,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9830.png",
          "name": "IMG_9830.png",
          "size": 1044751,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9831.png",
          "name": "IMG_9831.png",
          "size": 1084083,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9832.png",
          "name": "IMG_9832.png",
          "size": 1159770,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9833.png",
          "name": "IMG_9833.png",
          "size": 1164456,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9834.png",
          "name": "IMG_9834.png",
          "size": 1128223,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9835.png",
          "name": "IMG_9835.png",
          "size": 1142230,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9836.png",
          "name": "IMG_9836.png",
          "size": 1045101,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9837.png",
          "name": "IMG_9837.png",
          "size": 1110745,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9838.png",
          "name": "IMG_9838.png",
          "size": 1051287,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9839.png",
          "name": "IMG_9839.png",
          "size": 1108305,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9840.png",
          "name": "IMG_9840.png",
          "size": 1180175,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9841.png",
          "name": "IMG_9841.png",
          "size": 996091,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9842.png",
          "name": "IMG_9842.png",
          "size": 1090049,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9843.png",
          "name": "IMG_9843.png",
          "size": 1081637,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9844.png",
          "name": "IMG_9844.png",
          "size": 1129562,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9845.png",
          "name": "IMG_9845.png",
          "size": 1197376,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9846.png",
          "name": "IMG_9846.png",
          "size": 1196456,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9847.png",
          "name": "IMG_9847.png",
          "size": 1098535,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9848.png",
          "name": "IMG_9848.png",
          "size": 1141442,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9849.png",
          "name": "IMG_9849.png",
          "size": 1123591,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9850.png",
          "name": "IMG_9850.png",
          "size": 1171451,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9851.png",
          "name": "IMG_9851.png",
          "size": 1025615,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9852.png",
          "name": "IMG_9852.png",
          "size": 1138644,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9853.png",
          "name": "IMG_9853.png",
          "size": 941956,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9854.png",
          "name": "IMG_9854.png",
          "size": 909488,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9855.png",
          "name": "IMG_9855.png",
          "size": 1135247,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9856.png",
          "name": "IMG_9856.png",
          "size": 1079473,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9857.png",
          "name": "IMG_9857.png",
          "size": 1146412,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9858.png",
          "name": "IMG_9858.png",
          "size": 1027001,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9859.png",
          "name": "IMG_9859.png",
          "size": 1141219,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9860.png",
          "name": "IMG_9860.png",
          "size": 1141540,
          "extension": ".png",
          "type": "file"
        },
        {
          "path": "../src/public/gears/IMG_9861.png",
          "name": "IMG_9861.png",
          "size": 1025141,
          "extension": ".png",
          "type": "file"
        }
      ],
      "size": 41381579,
      "type": "directory"
    }
  ],
  "size": 242839916,
  "type": "directory"
}